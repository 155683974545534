export const environment = {
  production: true,
  apiProtocol: "https://",
  apiDomain: "api.rebound.flux.host",
  auth: {
    domain: "https://flux-host-dev-cv7hru.us1.zitadel.cloud",
    clientId: "286103942960597356",
    redirectBaseUri: "https://rebound.flux.host"
  }
};
